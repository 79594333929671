import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Login.vue'), // 登录
  },
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/main/Main.vue'),  //主界面
    redirect:{name:'home'},
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/homeNew.vue') // 首页--中间svg
        // component: () => import('@/views/home/home.vue') // 首页--中间地图
      },
      {
        path: '/stationSinglePage',
        name: 'stationSinglePage',
        component: () => import('@/views/pageConfig/StationSinglePage.vue') // 站点单页面(数据只能选择当前站点的数据)
        // component: () => import('@/views/pageConfig/svgMain5.vue') // 站点公共页面（数据可以选择所有站点的数据）
      },
      {
        path: '/stationSinglePageOld',
        name: 'stationSinglePageOld',
        component: () => import('@/views/pageConfig/StationSinglePageOld.vue') // 旧参考----站点单页面(数据只能选择当前站点的数据)
        // component: () => import('@/views/pageConfig/svgMain5.vue') // 站点公共页面（数据可以选择所有站点的数据）
      }
    ],
  },



    // ---------------------- ！！以下均弃用！！！  -------------------------------
  {
    path: '/dataFilling',
    name: 'dataFilling',
    component: () => import('@/views/DataFilling/DataFilling.vue'), // 填数据 暂用
  },
  {
    path: '/dataFilling1',
    name: 'dataFilling1',
    component: () => import('@/views/DataFilling/DataFilling1.vue'), // 填数据 暂用
  },
  { //  开关组件
    path: '/SwitchMqtt',
    name: 'SwitchMqtt',
    component: () => import('../components/SwitchMqtt.vue')
  },
  { //  开关按钮动画组件
    path: '/switchAnimation',
    name: 'switchAnimation',
    component: () => import('../components/switchAnimation.vue')
  },
  { // 开关历史记录
    path: '/SwitchHistory',
    name: 'SwitchHistory',
    component: () => import('../components/SwitchHistory.vue')
  },
  { // k线图历史记录选项卡
    path: '/kHistory',
    name: 'kHistory',
    component: () => import('../components/kHistory.vue')
  },
  { // k线图历史记录弹出层
    path: '/kHistoryPopup',
    name: 'kHistoryPopup',
    component: () => import('../components/kHistoryPopup.vue')
  },
  { // k线图历史记录组件
    path: '/kEchartMqtt',
    name: 'kEchartMqtt',
    component: () => import('../components/kEchartMqtt.vue')
  },
  { // 数据实时警示弹出层
    path: '/realTimeAlertPopup',
    name: 'realTimeAlertPopup',
    component: () => import('../components/realTimeAlertPopup.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHashHistory(),
  routes
})

// 全局守卫：登录拦截
router.beforeEach((to, from, next) => {
    // 判断有没有登录
    if (store.state.userInfo && store.state.userInfo.userId) {
      next();
    } else {
      if (to.name == "login") {
        next();
      } else {
        router.push('login')
      }
    }
});

export default router
