import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

const app = createApp(App);

import Directive from './directive/index.js';
app.use(Directive);

import "./style/common.scss";

// echarts
import * as echarts from 'echarts';
app.config.globalProperties.$echarts = echarts;

// elementUI
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// 去掉浏览器警示信息
import 'default-passive-events';


// Swiper
// import Swiper from 'swiper';
// app.config.globalProperties.Swiper = Swiper;

// axios
app.config.globalProperties.$axios = axios;
import instance from './api/index';
import apiPath from './api/path';
app.config.globalProperties.$http = instance;
app.config.globalProperties.$apiPath = apiPath;

import qs from "qs"
app.config.globalProperties.$qs = qs;


// syncfusion
import { registerLicense } from '@syncfusion/ej2-base';
registerLicense(`Mgo+DSMBaFt/QHJqVVhjWlpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF9jTnxTdkdhX3tWcHdSRA==;Mgo+DSMBPh8sVXJ0S0R+XE9HcFRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS3xTcUdjWX1cdnBdRmlcUw==;ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkJhWX9ccnJRTmFbV0Y=;NjgwMzI1QDMyMzAyZTMyMmUzMGlpbVJndVh4WFU1RmJBZldyL1hHWFlDeklXeEhiQmFZaEVvYmVkcDJnbTQ9;NjgwMzI2QDMyMzAyZTMyMmUzMFRXclA0dUcydmpJVE1iZDNqREtscWNvYWRyT09kS3NCTXQ4Q2RnMzdwaVU9;NRAiBiAaIQQuGjN/V0Z+Xk9EaFxEVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdEVkW39ecnZTQmhZVEd3;NjgwMzI4QDMyMzAyZTMyMmUzMFFwdElhVFJTRmlHZDhzd1U5Y3NxbzU3SENHdkZINkN6SzA0K0h5aG5LOGs9;NjgwMzI5QDMyMzAyZTMyMmUzMEoyZzE2VlhZWEdRbEw4YzhqVWJERjJrdWdxNDh6bzNFQnpuaUpuUGVIZDQ9;Mgo+DSMBMAY9C3t2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkJhWX9ccnJRTmhdVEY=;NjgwMzMxQDMyMzAyZTMyMmUzMGh3YWp2clN4UklmU2N4ZFVkSm41NW1icjBPcWloMHl3bHYyYTRzc3ZLMzg9;NjgwMzMyQDMyMzAyZTMyMmUzMGNxOUZrWkl6b1pLTHU1L2VqeXExNCtRbzNDSzdlakozUVp1NFlNZWUwUTg9;NjgwMzMzQDMyMzAyZTMyMmUzMFFwdElhVFJTRmlHZDhzd1U5Y3NxbzU3SENHdkZINkN6SzA0K0h5aG5LOGs9`);

// @syncfusion/ej2-vue-navigations
import { SidebarPlugin, MenuPlugin, ToolbarPlugin, BreadcrumbPlugin, TreeViewPlugin, TabPlugin, AccordionPlugin } from '@syncfusion/ej2-vue-navigations';
app.use(SidebarPlugin)  // 侧栏
app.use(MenuPlugin)    // 菜单栏
app.use(ToolbarPlugin)  // 工具栏
app.use(BreadcrumbPlugin)  // 面包屑
app.use(TreeViewPlugin)  // 树形
app.use(TabPlugin) // 标签页
app.use(AccordionPlugin) // 手风琴
// @syncfusion/ej2-vue-buttons
import { ButtonPlugin, RadioButtonPlugin } from '@syncfusion/ej2-vue-buttons';
app.use(ButtonPlugin)  // 按钮
app.use(RadioButtonPlugin) // 单选
// @syncfusion/ej2-base
// import { enableRipple } from '@syncfusion/ej2-base';
// @syncfusion/ej2-vue-grids
import { GridPlugin } from '@syncfusion/ej2-vue-grids';
app.use(GridPlugin)  // 表格
// @syncfusion/ej2-vue-treegrid
import { TreeGridPlugin } from '@syncfusion/ej2-vue-treegrid';
app.use(TreeGridPlugin);
// @syncfusion/ej2-vue-inputs
import { TextBoxPlugin, UploaderPlugin, NumericTextBoxPlugin } from '@syncfusion/ej2-vue-inputs';
app.use(TextBoxPlugin);  // 文本框
app.use(UploaderPlugin);  // 上传
app.use(NumericTextBoxPlugin);  // 数字文本框
// @syncfusion/ej2-vue-popups
import { DialogPlugin } from '@syncfusion/ej2-vue-popups';
app.use(DialogPlugin);  // 弹框
// @syncfusion/ej2-vue-layouts
import { SplitterPlugin } from '@syncfusion/ej2-vue-layouts';
app.use(SplitterPlugin);
// @syncfusion/ej2-vue-dropdowns
import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns';
app.use(DropDownListPlugin);   // 下拉列表
import { CalendarPlugin, DatePickerPlugin } from '@syncfusion/ej2-vue-calendars';
app.use(CalendarPlugin);  // 日历
app.use(DatePickerPlugin);  // 日期选择器
import { SchedulePlugin } from '@syncfusion/ej2-vue-schedule';
app.use(SchedulePlugin);   // 日程表

// 表格 / 分页
// import { GridPlugin } from '@syncfusion/ej2-vue-grids';
// ,PagerPlugin.use(PagerPlugin).use(GridPlugin)
app.use(store).use(router).use(ElementPlus).mount('#app');



// vue-grid-layout
import VueGridLayout from 'vue-grid-layout';
app.use(VueGridLayout);

